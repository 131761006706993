import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 动态设置网页标题
import VueWechatTitle from 'vue-wechat-title'
	Vue.use(VueWechatTitle)
// px2rem 自适应
// import 'lib-flexible'
// 表格自动滚动
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// 引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
//播放器相关
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import Vant from 'vant';
import 'vant/lib/index.css';
import x2js from 'x2js' // xml数据处理插件
Vue.prototype.$x2js = new x2js() // 创建x2js对象，挂到vue原型上
Vue.use(Vant);

// echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts //挂载到Vue实例上面



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
