import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/merchantsMap'

  },
  {
    path: '/countMap',
    component: () => import('../views/country/countMap.vue'),
  },
  {
    path: '/merchantsMap',
    name: 'merchantsMap',
    component: () => import('../views/merchantsMap.vue'),
    meta:{
      title:'云南招商'
    }
  },
  {
    path: '/countryDetail',
    name: 'countryDetail',
    component: () => import('../views/country/countryDetail.vue'),
    meta:{
      title:'云南招商'
    }
  },
  {
    path: '/countMapDetail',
    name: 'CountMapDetail',
    component: () => import('../views/country/countMapDetail.vue'),
    meta:{
      title:'云南招商'
    }
  },
  {
    path: '/peopleContact',
    name: 'peopleContact',
    component: () => import('../views/peopleContact.vue'),
    meta:{
      title:'人才联络'
    }
  }, {
    path: '/portForwarding',
    name: 'portForwarding',
    component: () => import('../views/portForwarding.vue'),
    meta:{
      title:'项目推进'
    }
  },
  {
    path: '/portForwardingClassify',
    name: 'portForwardingClassify',
    component: () => import('../views/portForwardingClassify.vue'),
    meta:{
      title:'项目列表'
    }
  },
  {
    path: '/portForwardingDetail',
    name: 'portForwardingDetail',
    component: () => import('../views/portForwardingDetail.vue'),
    meta:{
      title:'项目详情'
    }
  },
  {
    path:'/talentDetail',
    name: 'talentDetail',
    component: () => import('../views/talentDetail.vue'),
    meta:{
      title:'人才详情'
    }
  },
  {
    path:'/talentClassify',
    name: 'talentClassify',
    component: () => import('../views/talentClassify.vue'),
    meta:{
      title:'人才类别'
    }
  },
  {
    path:'/famousEnterprise',
    name: 'famousEnterprise',
    component: () => import('../views/famousEnterprise.vue'),
    meta:{
      title:'名企联络'
    }
  },
  {
    path:'/centralEnterprises',
    name: 'centralEnterprises',
    component: () => import('../views/centralEnterprises.vue'),
    meta:{
      title:'央企联络'
    }
  },
  {
    path:'/enterpriseClassify',
    name: 'enterpriseClassify',
    component: () => import('../views/enterpriseClassify.vue'),
    meta:{
      title:'企业列表'
    }
  },
  {
    path:'/enterpriseDetailFamous',
    name: 'enterpriseDetailFamous',
    component: () => import('../views/enterpriseDetailFamous.vue'),
    meta:{
      title:'名企详情'
    }
  },
  {
    path:'/enterpriseDetailCentral',
    name: 'enterpriseDetailCentral',
    component: () => import('../views/enterpriseDetailCentral.vue'),
    meta:{
      title:'央企详情'
    }
  },
  {
    path:'/merchantsInformation',
    name: 'merchantsInformation',
    component: () => import('../views/merchantsInformation.vue'),
    meta:{
      title:'招商资讯'
    }
  },
  {
    path:'/informationDetail',
    name:'informationDetail',
    component: () => import('../views/informationDetail.vue'),
    meta:{
      title:'资讯详情'
    }
  },
  {
    path:'/contactLog',
    name:'contactLog',
    component: () => import('../views/contactLog.vue'),
    meta:{
      title:'联络日志'
    }
  },
  {
    path:'/details',
    name:'details',
    component: () => import('../views/details.vue'),
    meta:{
      title:''
    }
  },
  {
    path:'/features',
    name:'Features',
    component: () => import('../views/features/features'),
    meta:{
      title:''
    }
  },
  {
    path:'/features/details',
    name:'Featuresdetails',
    component: () => import('../views/features/details'),
    meta:{
      title:''
    }
  },
  {
    path:'/detailes',
    name:'detailes',
    component: () => import('../views/detailes.vue'),
    meta:{
      title:''
    }
  },
  {
    path:'/leadingExpertClassify',
    name:'leadingExpertClassify',
    component: () => import('../views/leadingExpertClassify.vue'),
    meta:{
      title:''
    }
  },
  {
    path:'/leadingExpertDetail',
    name:'leadingExpertDetail',
    component: () => import('../views/leadingExpertDetail.vue'),
    meta:{
      title:''
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
